require('mainCss');
require('../icons/iconfont.css');
require('aos/dist/aos.css');
require('typeface-titillium-web')
var AOS = require('aos');  
var JStoy = require('jstoy');  


$(document).ready(function () {
    var jstoy = new JStoy();
    AOS.init();

    /*
       var cleanUp, debounce, i, len, ripple, rippleContainer, ripples, showRipple;

       var debounce = function(e) {
       var ripple = this;
       setTimeout(function(){
       $(ripple).find('span').remove();
       },2000)
       };

       var showRipple = function(e) {
       var pos, ripple, rippler, size, style, x, y;
       ripple = this;
       rippler = document.createElement('span');
       size = ripple.offsetWidth;
       pos = ripple.getBoundingClientRect();
       console.log(e.pageY);
       console.log(e.clientY);
       x = e.pageX - pos.left - (size / 2);
       y = e.clientY - pos.top - (size / 2);
       style = 'top:' + y + 'px; left: ' + x + 'px; height: ' + size + 'px; width: ' + size + 'px;';
       $(ripple).find('.ripple-container').append(rippler);
       return rippler.setAttribute('style', style);
       };


       $('[ripple]').append('<div class="ripple-container"></div>');
       $(document)
       .on('mousedown', '[ripple]', showRipple)
       .on('mouseup', '[ripple]', debounce)

       ripples = document.querySdebounce(cleanUp, 2000)electorAll('[ripple]');
       for (i = 0, len = ripplesdebounce(cleanUp, 2000).length; i < len; i++) {
       ripple = ripples[i];
       rippleContainer = docdebounce(cleanUp, 2000)ument.createElement('div');
       rippleContainer.classdebounce(cleanUp, 2000)Name = 'ripple-container';
       ripple.addEventListendebounce(cleanUp, 2000)er('mousedown', showRipple);
       ripple.addEventListendebounce(cleanUp, 2000)er('mouseup', debounce(cleanUp, 2000));
       ripple.rippleContainer = rippleContainer;
       ripple.appendChild(rippleContainer);
     }*/

})
