/*
 * jstoy
 * 
 */

var JStoy = function(){
    this.init();
}

JStoy.prototype = {

    init: function(){
        var _ = this;
        _.initG11N();
        _.initMap();

        _.handle = {
            submitComment: _.submitComment.bind(_)
        }

        // 顶部黏住
        $(window).on('scroll', function(e){
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if( scrollTop > 0 ){
                $('.header').addClass('sticky');
            } else {
                $('.header').removeClass('sticky');
            }
        }).trigger('scroll');

        $(document)
        .on('click', '#comment-submit', _.handle.submitComment)
        .on('keydown', '.header-search-input', function(e){
            if( e.keyCode == 13){
                var keyword = $(this).val();
                location.href = '/search?keyword='+keyword;
            }
        }).on('click', '.footer-backtop-link',function(){
            $("html, body").animate({scrollTop: 0}, 1000);
        }).on('click','.navigation-search-toggle',function(){
            $('.header-search-form').addClass('active');
            $('.header-search-input').trigger('focus');
        }).on('click','.header-search-close', function(){
            $('.header-search-form').removeClass('active');
        })

    },

    initG11N: async function(){
        var _ = this;
        $.ajax({
           url: '/api/g11n',
           async: false,
           success: function(res){
               if( res.code == 200 ){
                   _.g11n = res.data;
               }
           }
        })
    },

    initMap: function(){
        var _ = this;
        if($('#map').length == 0){
            return;
        }
        var coord = $('#map').attr('data-coord');
        coord = !!coord ? coord.split(',') : [116.756092,23.46596];
        var title = $('#map').attr('data-title') || _.g11n.Company;
        var s = document.createElement('script');
        s.src = 'http://webapi.amap.com/maps?v=1.3&key=29076a35fd5abd25add2eb561488a73f';
        (document.head || document.body).appendChild(s);
        s.onload = function(){
            window.addEventListener('load',function(){
                var googleLayer = _.g11n.Lang == 'en-US' ? new AMap.TileLayer({
                    getTileUrl: 'http://mt{1,2,3,0}.google.cn/vt/lyrs=m&hl=en-US&gl=cn&x=[x]&y=[y]&z=[z]&s=Galile'
                }) : new AMap.TileLayer();

                var map = new AMap.Map('map', {
                    resizeEnable: true,
                    center: coord,
                    zoom: 14,
                    layers: [googleLayer]
                });

                var marker = new AMap.Marker({
                    map: map,
                    position: coord
                });

                marker.setLabel({
                    offset: new AMap.Pixel(20, -20),
                    content: title
                });
            }, false)
        }
    },

    // 提交留言
    submitComment: function(){
        var _ = this;
        var name = $('#comment-name').val();
        var telephone = $('#comment-telephone').val();
        var email = $('#comment-email').val();
        var text = $('#comment-message').val();


        if(/^\s*$/.test(name)){
            layer.msg(_.g11n.PleaseEnterYourName)
            $('#comment-name').trigger('focus');
            return;
        }

        if (/^\d+$/.test(telephone) == false){
            layer.msg(_.g11n.PleaseEnterAValidPhone)
            $('#comment-telephone').trigger('focus');
            return;
        }

        if(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) == false){
            layer.msg(_.g11n.PleaseEnterAValidEmail)
            $('#comment-email').trigger('focus');
            return;
        }

        if(/^\s*$/.test(text)){
            layer.msg(_.g11n.PleaseEnterMessage)
            $('#comment-message').trigger('focus');
            return;
        }
        var data = {
            Id: 0,
            Name: name,
            Phone: telephone,
            Email: email,
            Text: text,
            Remark: ''
        };
        $.ajax({
            url: '/api/savecomment',
            contentType: 'application/json',
            dataType: 'json',
            type: 'POST',
            data: JSON.stringify(data),
            success: function(res){
                layer.msg(_.g11n.SendSuccess);
                $('#comment-name').val('');
                $('#comment-telephone').val('');
                $('#comment-email').val('');
                $('#comment-message').val('');
            }
        })
    },
}

module.exports = JStoy;
